const prefix =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/api'
    // ? 'https://test.europion.hu/api'
    : `${window.location.origin}/api`;

export const LOGIN = `${prefix}/v1.1/auth/admin/login`;
export const LOGOUT = `${prefix}/v1.1/auth/admin/logout`;
export const PASSWORD_RESET = `${prefix}/v1.1/auth/resetpassword`;
export const USER_LIST = `${prefix}/v1.1/user/list`;
export const USER = `${prefix}/v1.1/user`;
export const SURVEY = `${prefix}/v1.1/survey`;
export const SURVEY_LIST = `${prefix}/v1.1/survey/list`;
export const TAG = `${prefix}/v1.1/tag`;
export const TAG_LIST = `${prefix}/v1.1/tag/list`;
export const GIFT = `${prefix}/v1.1/gift`;
export const GIFT_LIST = `${prefix}/v1.1/gift/list`;
export const COUPON_LIST = `${prefix}/v1.1/gift/coupon/list`;
export const COUPON = `${prefix}/v1.1/gift/coupon`;
export const QUESTION = `${prefix}/v1.1/question`;
export const USER_FILTER = `${prefix}/v1.1/user-filter`;
export const USER_FILTER_LIST = `${prefix}/v1.1/user-filter/list`;
export const PUSH = `${prefix}/v1.1/push`;
export const COUPON_BATCH_UPLOAD = `${prefix}/v1.1/gift/coupon/batchupload`;
